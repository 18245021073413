import clsx from 'clsx'
import { useControlField, useField } from 'remix-validated-form'
import { Checkbox, Label } from '~/components/ui'
import { cn } from '~/utils'

type CheckboxFieldProps = {
  className?: string
  label: string
  name: string
  disabled?: boolean
  defaultValue?: boolean
  onBlur?: () => void
  onCheckedChange?: (value: string | boolean) => void
}

export default function CheckboxField(props: CheckboxFieldProps) {
  const {
    className,
    name,
    label,
    disabled,
    defaultValue,
    onBlur: customOnBlur,
    onCheckedChange,
  } = props
  const { error, getInputProps, defaultValue: previousValue } = useField(name)
  const { onBlur } = getInputProps()
  const [value, setValue] = useControlField(name)

  return (
    <div className={cn('flex items-center space-x-2', className)}>
      <Checkbox
        id={name}
        name={name}
        disabled={disabled}
        checked={typeof value !== 'undefined' ? !!value : defaultValue}
        onCheckedChange={(value) => {
          setValue(value.valueOf())
          onCheckedChange?.(value.valueOf())
          customOnBlur?.()
          onBlur?.()
        }}
        className={clsx(
          previousValue &&
            value != previousValue &&
            'text-yellow-300 ring-2 ring-yellow-300 ring-offset-2'
          // ((!previousValue && value === true) || value === 'on') &&
          //   'text-green-400 ring-2 ring-offset-2 ring-green-400'
        )}
      />
      <Label htmlFor={name} className="font-normal">
        {label}
      </Label>
    </div>
  )
}
